<template>
  <div>
    <!-- 获取小程序二维码 -->
    <el-card>
      <div class="text">
        <div class="title">获取小程序二维码</div>
        <!-- 二维码类型 -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item
            label="二维码类型:"
            prop="type"
          >
            <el-radio
              v-model="ruleForm.type"
              label="0"
            >小程序码（圆形）</el-radio>
            <el-radio
              v-model="ruleForm.type"
              label="1"
            >二维码（方形）</el-radio>
          </el-form-item>

          <el-form-item label="小程序页面路径">
            <el-input
              readonly
              value="pages/home/index"
              class="W_input"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="code_pic">

        <!-- 二维码 -->
        <img src="../../../assets/image/code1.jpg">
        <el-button
          type="primary"
          round
          class="codebtn"
          @click="downloadQRcode"
        >下载二维码</el-button>
      </div>
    </el-card>

    <!-- 微信Acess Token管理 -->
    <el-card>
      <div class="title">微信Acess Token管理</div>
      <div class="box">
        <div class="token">Token：{{token}} </div>
        <br><br>
        <div class="refresh">最后一次刷新时间：{{refresh}} </div>
      </div>
      <el-button
        type="warning"
        round
        class="btn"
        @click="refreshtoken"
      >强制刷新AccessToken</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "W_management",
  data() {
    return {
      ruleForm: {
        type: "0"
      },
      rules: {
        type: [{ required: true, message: "请选择二维码类型", trigger: "blur" }]
      },
      code: "",
      token: window.sessionStorage.getItem("token"),
      refresh: ""
    };
  },
  async created() {
    this.get_token_info();
  },
  methods: {
    // 强制刷新token
    async refreshtoken() {
      const { data: res } = await this.$http.post("/auth/login", null, {
        params: {
          username: "admin",
          password: "admin123"
        }
      });
      console.log(res);
      window.sessionStorage.setItem("token", res.entity.token);
      this.token = res.entity.token;
      this.refresh = res.entity.lastRefreshDate;
      this.get_token_info();
    },

    // 获取当前token信息
    async get_token_info() {
      const { data: res } = await this.$http.get(`/wechat/getTokenInfo`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        }
      });
      this.token = window.sessionStorage.getItem("token");
      this.refresh = res.entity.lastRefreshDate;
    },

    // 下载二维码
    async downloadQRcode() {
      const { data: res } = await this.$http.get(
        `/wechat/wxCode`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          params: {
            type: this.ruleForm.type,
            path: 'pages/home/index',
            width: 300
          }
        }
      );
      console.log(res);
      window.open(res.entity.imgURL, '_blank');
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.W_input {
  width: 450px;
}
.box {
  width: 100%;
  display: inline-block;
}
.token,
.refresh {
  text-align: left;
  display: inline-block;
}
.box2 {
  margin-left: 5px;
  display: inline-block;
}
.codebtn {
  margin-left: 20px;
  margin-bottom: 20px;
}
.btn {
  margin-top: 20px;
  display: block;
}
.text {
  display: inline;
  float: left;
}
.code_pic {
  display: inline;
  float: right;
  margin-right: 800px;
}
</style>
