<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/k_list' }">知识列表</el-breadcrumb-item>
      <el-breadcrumb-item>批量导入</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 步骤条 -->
    <el-card>
      <el-steps :active="active">
        <el-step
          title="上传用户Excel"
          icon="el-icon-upload"
        ></el-step>
        <el-step
          title="完成"
          icon="el-icon-success"
        ></el-step>
      </el-steps>
    </el-card>

    <!-- 上传部分 -->
    <el-card class="card">
      <el-select
        v-model="activity"
        placeholder="请选择活动"
        clearable
      >
        <el-option
          v-for="item in activitylist"
          :key="item.id"
          :label="item.activityName"
          :value="item.id"
          class="select"
        >
        </el-option>
      </el-select>
      <el-upload
        class="upload-demo"
        action="https://api.bnuztuhms.cn/knowledge/importExcel"
        :headers="importHeaders"
        :data="{activityId: activity}"
        :file-list="fileList"
        :on-success="uploadSuccess"
        drag
        :limit="1"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button
        type="primary"
        round
        @click="download"
      >下载导入模板</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "K_add_plus",
  data() {
    return {
      active: 1,
      fileList: [],
      importHeaders: {
        token: window.sessionStorage.getItem("token")
      },
      importdata: {
        activityId: this.department
      },
      activity: "",
      activitylist: []
    };
  },
  methods: {
    // 获取活动列表
    async getActivitylist() {
      const { data: res } = await this.$http.get(`/activity/allList`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 0,
          pageSize: 100,
          sortProperty: "id"
        }
      });
      console.log(res);
      this.activitylist = res.entity.list;
    },

    async uploadSuccess(response) {
      if (response.code === 200) {
        this.$message.success("导入成功");
        this.active = 2;
      } else {
        this.$message.error("导入失败");
      }
      console.log(this.activity);
      console.log(response);
    },
    download() {
      window.location.href =
        "https://bnuztuhms-1252814078.cosgz.myqcloud.com/importKnowledgeEx.xls";
    }
  },
  created() {
    this.getActivitylist();
  }
};
</script>

<style lang="less" scoped>
.el-steps--horizontal {
  width: 50%;
  margin-left: 25%;
}
.card {
  text-align: center;
}
.upload-demo {
  margin-top: 20px;
  text-align: center;
}
.el-button {
  margin-top: 20px;
}
.el-select {
  width: 24%;
}
</style>
