<template>
  <div class="container">
    <div class="login_box">
      <!-- 校徽头像 -->
      <div class="circle">
        <!-- <img src="../assets/image/logo.png"> -->
      </div>
      <!-- 登录表单区域 -->
      <el-form class="login_form" label-width="0px" :model="loginform">
        <!-- 用户名 -->
        <el-form-item>
          <el-input prefix-icon="el-icon-user" v-model="loginform.username"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item>
          <el-input type="password" prefix-icon="el-icon-lock" v-model="loginform.password"></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btn">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginform: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    async login() {
      // 请求数据
      const { data: res } = await this.$http.post('/auth/login', null, {params: this.loginform})

      console.log(res);

      // 验证返回结果
      if(res.code === 200) {
        window.sessionStorage.setItem('token', res.entity.token)
        console.log(res.entity.token)
        this.$router.replace('/home')
        return this.$message.success('登录成功')
      } else {
        return this.$message.error('登录失败')
      }
    },
    reset() {
      this.loginform.username = ''
      this.loginform.password = ''
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    height: 100%;
    background: url(../assets/image/background.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translateX(-50%);
  }
  .login_box {
    width: 450px;
    height: 320px;
    background: white;
    border-radius: 25px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
  }
</style>
