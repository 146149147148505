<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/u_list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>批量导入</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 步骤条 -->
    <el-card>
      <el-steps :active="active">
        <el-step
          title="上传用户Excel"
          icon="el-icon-upload"
        ></el-step>
        <el-step
          title="完成"
          icon="el-icon-success"
        ></el-step>
      </el-steps>
    </el-card>

    <!-- 上传部分 -->
    <el-card class="card">
      <!-- https://www.cnblogs.com/loveliang/archive/2004/01/13/13578034.html -->
      <el-upload
        class="upload-demo"
        action="https://api.bnuztuhms.cn/userinfo/importExcel"
        :headers="importHeaders"
        :file-list="fileList"
        :on-success="uploadSuccess"
        drag
        :limit="1"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button
        type="primary"
        round
        @click="download"
      >下载导入模板</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "U_add_plus",
  data() {
    return {
      active: 1,
      fileList: [],
      importHeaders: {
        token: window.sessionStorage.getItem("token")
      }
    };
  },
  methods: {
    async uploadSuccess(response) {
      if(response.code === 200) {
        this.$message.success('导入成功');
        this.active = 2;
      } else {
        this.$message.error('导入失败');
      }
    },
    download() {
      window.location.href =
        "https://bnuztuhms-1252814078.cosgz.myqcloud.com/importEx.xls";
    }
  }
};
</script>

<style lang="less" scoped>
.el-steps--horizontal {
  width: 50%;
  margin-left: 25%;
}
.card {
  text-align: center;
}
.upload-demo {
  text-align: center;
}
.el-button {
  margin-top: 10px;
}
</style>
