import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
// 活动管理
import Activities_list from '../components/pages/Activities/A_list.vue'
import Activities_add from '../components/pages/Activities/A_add.vue'
import Activities_modify from '../components/pages/Activities/A_modify.vue'
import Activities_enroll from '../components/pages/Activities/A_enroll.vue'
import Activities_rank from '../components/pages/Activities/A_rank.vue'
// 部门管理
import Department_list from '../components/pages/Department/D_list.vue'
import Department_add from '../components/pages/Department/D_add.vue'
// 用户管理
import User_list from '../components/pages/User/U_list.vue'
import User_add from '../components/pages/User/U_add.vue'
import User_add_plus from '../components/pages/User/U_add_plus.vue'
// 报表管理
import Report_Medal from '../components/pages/Report/R_medal.vue'
import Report_Step from '../components/pages/Report/R_step.vue'
import Report_Health from '../components/pages/Report/R_health.vue'
import Report_Depart from '../components/pages/Report/R_depart.vue'
// 知识管理
import Knowledge_list from '../components/pages/Knowledge/K_list.vue'
import Knowledge_add from '../components/pages/Knowledge/K_add.vue'
import Knowledge_add_plus from '../components/pages/Knowledge/K_add_plus.vue'
import Knowledge_virtualstep_set from '../components/pages/Knowledge/K_virtualstep_set.vue'
import Knowledge_virtualsteps_record from '../components/pages/Knowledge/K_virtualsteps_record.vue'
// 微信模块管理
import Wechat_management from '../components/pages/WeChat/W_management.vue'
// 新闻管理
import New_list from '../components/pages/News/N_list.vue'
import New_add from '../components/pages/News/N_add.vue'
// 异常管理监控
import Monitor_list from '../components/pages/Monitor/M_list.vue'

// quill富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueRouter)
Vue.use(VueQuillEditor)

const routes = [
  {
    // 重定向到login界面
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/a_list',
    children: [
      {
        path: '/a_list',
        component: Activities_list
      },
      {
        path: '/a_add',
        component: Activities_add
      },
      {
        path: '/a_modify',
        component: Activities_modify
      },
      {
        path: '/a_enroll',
        component: Activities_enroll
      },
      {
        path: '/a_rank',
        component: Activities_rank
      },
      {
        path: '/d_list',
        component: Department_list
      },
      {
        path: '/d_add',
        component: Department_add
      },
      {
        path: '/u_list',
        component: User_list
      },
      {
        path: '/u_add',
        component: User_add
      },
      {
        path: '/u_add_plus',
        component: User_add_plus
      },
      {
        path: '/r_medal',
        component: Report_Medal
      },
      {
        path: '/r_step',
        component: Report_Step
      },
      {
        path: '/r_health',
        component: Report_Health
      },
      {
        path: '/r_depart',
        component: Report_Depart
      },
      {
        path: '/k_list',
        component: Knowledge_list
      },
      {
        path: '/k_add',
        component: Knowledge_add
      },
      {
        path: '/k_add_plus',
        component: Knowledge_add_plus
      },
      {
        path: '/k_virtualsteps_record',
        component: Knowledge_virtualsteps_record
      },
      {
        path: '/k_virtualstep_set',
        component: Knowledge_virtualstep_set
      },
      {
        path: '/w_management',
        component: Wechat_management
      },
      {
        path: '/n_list',
        component: New_list
      },
      {
        path: '/n_add',
        component: New_add
      },
      {
        path: '/m_list',
        component: Monitor_list
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to,from,next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next() 放行   next('/login') 强制跳转

  // 如果访问登录页  直接放行
  if(to.path === '/login') {
    return next()
  }

  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')

  // 如果token没有值，则强制跳转login界面
  if(!tokenStr) return next('/login')

  // 有值，则放行
  next()
})

export default router
