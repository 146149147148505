<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 查询 -->
    <el-card>
      <div class="title">查询</div>
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="新闻标题">
          <el-input
            v-model="news_name"
            class="news_input"
          ></el-input>
          <el-button
            type="primary"
            round
            class="btn"
            @click="search"
          >搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 表格 -->
    <el-card>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="全部"
          name="0"
        ></el-tab-pane>
        <el-tab-pane
          label="活动"
          name="1"
        ></el-tab-pane>
        <el-tab-pane
          label="通知"
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          label="知识"
          name="3"
        ></el-tab-pane>
      </el-tabs>
      <el-table
        :data="newslist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="title"
          label="标题"
        >
        </el-table-column>
        <el-table-column
          prop="category"
          label="类别"
        >
        </el-table-column>
        <el-table-column
          prop="author"
          label="作者"
        >
        </el-table-column>
        <el-table-column
          prop="releaseTime"
          label="发布时间"
        >
        </el-table-column>
        <el-table-column
          prop="readNumber"
          label="阅读量"
        >
        </el-table-column>
        <el-table-column
          prop="thumbs"
          label="点赞数"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "N_list",
  data() {
    return {
      news_name: "",
      activeName: 0,
      newslist: []
    };
  },
  methods: {
    // 获取新闻列表
    async get_newslist() {
      // const type = this.type
      const { data: res } = await this.$http.get(
        `/news/newsDTOList/${this.activeName} `,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            token: window.sessionStorage.getItem("token")
          },

          params: {
            pageNum: 0,

            pageSize: 1000,

            title: this.news_name
          }
        }
      );
      console.log(res);
      this.newslist = res.entity.list;
    },
    // 切换类型
    handleClick() {
      this.get_newslist();
    },

    // 删除新闻
    async del(index) {
      const id = this.newslist[index].id;
      const { data: res } = await this.$http.delete(`/news/${id}`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          token: window.sessionStorage.getItem("token")
        }
      });
      console.log(res);
      if (res.code === 200) {
        this.$message.success("删除成功");
        this.get_newslist();
      } else {
        this.$message.error("删除失败");
      }
    },

    // 修改新闻内容
    async edit(index) {
      const id = this.newslist[index].id;
      const { data: res } = await this.$http.put(`/news/${id}`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          token: window.sessionStorage.getItem("token")
        }
      });
      console.log(res);
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.get_newslist();
      } else {
        this.$message.error("修改失败");
      }
    },

    // 根据新闻标题搜索
    async search() {
      this.get_newslist();
    }
  },
  created() {
    this.get_newslist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.news_input {
  width: 450px;
}
.btn {
  margin-left: 100px;
}
</style>
