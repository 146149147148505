<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>其他记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 部门内步数报表 -->
    <el-card>
      <div class="title">部门内步数报表</div>
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="时间段:">
          <el-date-picker
            v-model="date1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="部门:">
          <el-select
            v-model="depart"
            placeholder="请选择部门"
          >
            <el-option
              v-for="item in depart_list"
              :key="item.value"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            round
            type="primary"
            @click="getdepartstepslist"
          >导出报表</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 部门之间平均步数报表 -->
    <el-card>
      <div class="title">部门之间平均步数报表</div>
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="时间段:">
          <el-date-picker
            v-model="date2"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-button
            round
            type="primary"
            @click="getdepartavglist"
          >导出报表</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "R_depart",
  data() {
    return {
      date1: "",
      date2: "",
      depart: "",
      depart_list: []
    }
  },
  methods: {
    // 获取部门列表
    async getdepartlist() {
      const { data: res } = await this.$http.get(`/departs/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 1,
          pageSize: 100
        }
      });
      console.log(res);
      this.depart_list = res.entity.list;
    },

    // 导出部门内步数报表
    getdepartstepslist() {
      let date = `${this.date1}`
      let start = date.split(",")[0].substring(0,10);
      let end = date.split(",")[1].substring(0,10);
      const obj = `https://api.bnuztuhms.cn/steps/getDepartInExcel?firstTime=${start}&lastTime=${end}&departId=${this.depart}&Token=${window.sessionStorage.getItem("token")}`;
      window.location.href = obj;
    },
    
    // 导出部门之间平均步数报表
    getdepartavglist() {
      let date = `${this.date2}`
      let firstTime = date.split(",")[0].substring(0,10);
      let lastTime = date.split(",")[1].substring(0,10);
      const obj = `https://api.bnuztuhms.cn/steps/getDepartAverExcel?firstTime=${firstTime}&lastTime=${lastTime}&Token=${window.sessionStorage.getItem("token")}`;
      window.location.href = obj;
    }
  },
  created() {
    this.getdepartlist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.el-button--primary {
  margin-left: 25px;
}
</style>
