<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>知识列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 查询 -->
    <el-card>
      <div class="title">查询</div>
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="活动">
          <el-select
            v-model="search_activity"
            clearable
            placeholder="请选择活动"
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目">
          <!-- 题目 -->
          <el-input
            v-model="search_question"
            class="ack_input"
          ></el-input>
          <el-button
            type="primary"
            round
            class="input_button"
            @click="search"
          >搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 导出记录 -->
    <el-card>
      <div class="title">导出记录</div>
      <el-form>
        <el-form-item label="活动">
          <el-select
            v-model="export_activity"
            clearable
            placeholder="请选择活动"
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="warning"
            round
            class="input_button"
            @click="download"
          >导出记录</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 表格 -->
    <el-card>
      <el-table
        :data="knowledgelist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="title"
          label="题目"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="contentA"
          label="A选项"
        >
        </el-table-column>
        <el-table-column
          prop="contentB"
          label="B选项"
        >
        </el-table-column>
        <el-table-column
          prop="contentC"
          label="C选项"
        >
        </el-table-column>
        <el-table-column
          prop="contentD"
          label="D选项"
        >
        </el-table-column>
        <el-table-column
          prop="answer"
          label="答案"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="analysis"
          label="解析"
          width="250"
        >
        </el-table-column>
        <el-table-column
          prop="activityTitle"
          label="活动名"
          width="300"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="160"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <!-- 修改知识 -->
    <el-dialog
      title="知识修改"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        :model="modify"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="题目"
          prop="question"
        >
          <el-input
            v-model="modify.question"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="A选项"
          prop="sectionA"
        >
          <el-input
            v-model="modify.sectionA"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="B选项"
          prop="sectionB"
        >
          <el-input
            v-model="modify.sectionB"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="C选项"
          prop="sectionC"
        >
          <el-input
            v-model="modify.sectionC"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="D选项"
          prop="sectionD"
        >
          <el-input
            v-model="modify.sectionD"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="答案"
          prop="answer"
        >
          <el-input
            v-model="modify.answer"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item label="解析">
          <el-input
            v-model="modify.analysis"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="活动"
          prop="activity"
        >
          <el-select
            v-model="modify.activity"
            placeholder="请选择"
            class="select"
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
// import { try } from 'q';
export default {
  name: "K_list",
  data() {
    return {
      form: [],
      dialogFormVisible: false,
      activitylist: [],
      knowledgelist: [],
      // 查询_活动
      search_activity: "",
      // 查询_题目
      search_question: "",
      // 导出记录_题目
      export_activity: "",
      question: "",
      export_record: "",
      modify: {
        question: "",
        sectionA: "",
        sectionB: "",
        sectionC: "",
        sectionD: "",
        answer: "",
        analysis: "",
        activity: ""
      },
      act_id: "",
      id: "",
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 获取知识列表
    async get_knowledgelist() {
      showLoading();
      try {
        const { data: res } = await this.$http.get(`/knowledge/list`, {
          headers: {
            Accept: "application/json;charset=UTF-8",
            token: window.sessionStorage.getItem("token")
          },
          params: {
            pageNum: this.currentpage,
            pageSize: 5,
            activityId: this.search_activity,
            title: this.search_question
          }
        });
        if (res) {
          hideLoading();
        }
        if (res.code === 200) {
          // hideLoading();
          this.knowledgelist = res.entity.list;
          this.total = res.entity.total;
          this.pagecount = res.entity.pages;
        }
      } catch (error) {
        hideLoading();
        this.$message.error("获取知识列表失败");
      }
    },

    // 获取活动列表
    async get_activitylist() {
      const { data: res } = await this.$http.get(`/activity/allList`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          token: window.sessionStorage.getItem("token")
        },

        params: {
          pageNum: 0,

          pageSize: 200
        }
      });
      console.log(res);
      this.activitylist = res.entity.list;
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.get_knowledgelist();
    },

    // 查询
    async search() {
      this.get_knowledgelist();
    },

    // 编辑知识
    async edit(index) {
      this.dialogFormVisible = true;
      this.act_id = this.knowledgelist[index].activityId;
      this.id = this.knowledgelist[index].id;
      const { data: res } = await this.$http.get(`/knowledge/${this.act_id}`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          token: window.sessionStorage.getItem("token")
        }
      });
      console.log(res);
      this.modify.activity = res.entity[0].activityId;
      this.modify.question = res.entity[0].title;
      this.modify.sectionA = res.entity[0].contentA;
      this.modify.sectionB = res.entity[0].contentB;
      this.modify.sectionC = res.entity[0].contentC;
      this.modify.sectionD = res.entity[0].contentD;
      this.modify.answer = res.entity[0].answer;
      this.modify.analysis = res.entity[0].analysis;
    },

    // 上传修改知识
    async submit() {
      const { data: res } = await this.$http.put(
        `/knowledge/${this.id}`,
        {
          activityId: this.modify.activity,
          activityTitle: null,
          analysis: this.modify.analysis,
          answer: this.modify.answer,
          contentA: this.modify.sectionA,
          contentB: this.modify.sectionB,
          contentC: this.modify.sectionC,
          contentD: this.modify.sectionD,
          createDate: null,
          id: this.id,
          modifiedDate: null,
          title: this.modify.question
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      console.log(res);
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.dialogFormVisible = false;
        this.get_knowledgelist();
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("修改失败");
      }
    },

    // 删除知识
    async del(index) {
      let id = this.knowledgelist[index].id;
      this.$confirm("此操作将永久删除该知识, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data: res } = await this.$http.delete(`/knowledge/${id}`, {
            headers: {
              Accept: "application/json;charset=UTF-8",
              token: window.sessionStorage.getItem("token")
            }
          });
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.get_knowledgelist();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    // 导出记录
    download() {
      if (this.export_activity === "") {
        this.$message.error("请选择活动");
      } else {
        const obj = `https://api.bnuztuhms.cn/knowledge/getKnowledgeExcel?activityId=${
          this.export_activity
        }&Token=${window.sessionStorage.getItem("token")}`;
        window.location.href = obj;
      }
    }
  },
  created() {
    this.get_knowledgelist();
    this.get_activitylist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.ack_input {
  width: 450px;
}
.input_button {
  margin-left: 100px;
}
</style>
