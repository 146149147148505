<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/a_list' }">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加活动</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card class="box-card">
      <!-- 选项卡 -->
      <el-tabs v-model="activeName">
        <!-- 普通活动选项卡 -->
        <el-tab-pane
          label="普通活动"
          name="normal"
        >
          <el-form
            ref="normal_form"
            :model="normal_form"
            :rules="rules"
            size="medium"
          >
            <!-- 普通名称输入框 -->
            <el-form-item
              label="活动名称"
              prop="name"
            >
              <el-input
                v-model="normal_form.name"
                class="activity_input"
              ></el-input>
            </el-form-item>
            <!-- 时间选择器 -->
            <div class="block">
              <el-form-item
                label="活动时间"
                prop="date"
              >
                <el-date-picker
                  v-model="normal_form.date"
                  type="datetimerange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:00']"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <!-- 报名资格 -->
            <el-form-item
              label="报名资格"
              prop="qualification"
            >
              <el-radio-group v-model="normal_form.qualification">
                <el-radio label="0">全部用户</el-radio>
                <el-radio label="1">仅工会成员</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 活动封面 -->
            <el-form-item
              label="活动封面"
              prop="cover"
            >
              <el-upload
                class="upload-demo"
                action="https://api.bnuztuhms.cn/cloud/upload"
                :headers="importHeaders"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture"
                :on-success="uploadSuccess"
                :limit="1"
              >
                <el-button
                  size="small"
                  type="primary"
                >点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                >只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>
            <!-- 步数要求 -->
            <el-form-item
              label="步数要求"
              prop="steps"
            >
              <el-input
                type="text"
                v-model="normal_form.steps"
                class="activity_input"
                placeholder="达到该步数的参与者可活动一枚活动勋章"
              ></el-input>
            </el-form-item>
            <!-- 活动介绍 -->
            <el-form-item
              label="活动介绍"
              prop="introduction"
            >
            </el-form-item>
            <quill-editor
              ref="myTextEditor"
              v-model="normal_form.introduction"
              :options="editorOption"
              style="height:600px;"
            ></quill-editor>
            <el-button
              type="primary"
              round
              @click="normal_activity_add()"
            >添加活动</el-button>
          </el-form>
        </el-tab-pane>

        <!-- 虚拟活动路线选项卡 -->
        <el-tab-pane
          label="虚拟路线活动"
          name="vitual"
        >
          <el-form
            ref="virtual_form"
            :model="virtual_form"
            :rules="rules"
            size="medium"
          >
            <!-- 虚拟活动名输入框 -->
            <el-form-item
              label="活动名称"
              prop="name"
            >
              <el-input
                v-model="virtual_form.name"
                class="activity_input"
              ></el-input>
            </el-form-item>
            <!-- 时间选择器 -->
            <div class="block">
              <el-form-item
                label="活动时间"
                prop="date"
              >
                <el-date-picker
                  v-model="virtual_form.date"
                  type="datetimerange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:00']"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <!-- 报名资格 -->
            <el-form-item
              label="报名资格"
              prop="qualification"
            >
              <el-radio-group v-model="virtual_form.qualification">
                <el-radio label="0">全部用户</el-radio>
                <el-radio label="1">仅工会成员</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 活动封面 -->
            <el-form-item
              label="活动封面"
              prop="cover"
            >
              <!-- 记得填action接口地址 -->
              <el-upload
                class="upload-demo"
                action="https://api.bnuztuhms.cn/cloud/upload"
                :headers="importHeaders"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture"
                :on-success="cover_uploadSuccess"
                :limit="1"
              >
                <el-button
                  size="small"
                  type="primary"
                >点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                >只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>
            <!-- 步数要求 -->
            <el-form-item
              label="步数要求"
              prop="steps"
            >
              <el-input
                type="text"
                v-model="virtual_form.steps"
                class="activity_input"
                placeholder="达到该步数的参与者可活动一枚活动勋章"
              ></el-input>
            </el-form-item>
            <!-- 区分性别 -->
            <el-form-item
              label="关卡是否区分性别"
              prop="gender"
            >
              <el-radio-group v-model="virtual_form.gender">
                <el-radio label="0">不区分</el-radio>
                <el-radio label="1">区分</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 虚拟路线关卡数 -->
            <el-form-item
              label="虚拟路线关卡数(起点0不算关卡,但关卡目标步数需要在首位填充0,关卡图片需要第一张上传起点图)"
              prop="level_number"
            >
            </el-form-item>
            <el-input
              v-model="virtual_form.level"
              class="activity_input"
            ></el-input>
            <!-- 关卡目标数 -->
            <el-form-item
              label="关卡目标步数(如果区分性别，先输入男性每关卡步数数值，然后输入女性每关卡步数数值;如果不区分性别，则直接输入每关卡步数数值即可。每个数值以英文逗号分隔)"
              prop="level_setnumber"
            >
            </el-form-item>
            <el-input
              v-model="virtual_form.level_steps"
              class="activity_input"
            ></el-input>
            <!-- 关卡图片 -->
            <el-form-item
              label="关卡图片(对应关卡的图片,按照关卡数号依次放入.注：假设有3关,若无性别区分,则图片依次为起点图+依次3张关卡图,若有,则图片依次为起点图+依次3张男性关卡图+起点图+依次3张女性关卡图,前半段序号为男性,后半段序号为女性)"
              prop="level_photo"
            >
            </el-form-item>
            <el-upload
              class="upload-demo"
              action="https://api.bnuztuhms.cn/cloud/upload"
              :headers="importHeaders"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
              :on-success="uploadSuccess_level"
              multiple
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
            <!-- 活动介绍 -->
            <el-form-item
              label="活动介绍"
              prop="introduction"
            >
            </el-form-item>
            <quill-editor
              ref="myTextEditor"
              v-model="virtual_form.introduction"
              :options="editorOption"
              style="height:600px;"
            ></quill-editor>
            <!-- 虚拟路线介绍 -->
            <el-form-item
              label="虚拟路线介绍"
              prop="virtue_introduction"
            >
            </el-form-item>
            <quill-editor
              ref="myTextEditor"
              v-model="virtual_form.virtual_introduction"
              :options="editorOption"
              style="height:600px;"
            ></quill-editor>
            <el-button
              type="primary"
              round
              @click="virtual_activity_add()"
            >添加活动</el-button>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
var mytoken = window.sessionStorage.getItem("token");
// console.log(mytoken);
export default {
  name: "A_add",
  data() {
    return {
      importHeaders: { token: mytoken },
      fileList: [],
      editorOption: {
        placeholder: "编辑文章内容"
      },
      activeName: "normal",
      normal_form: {
        name: "",
        date: "",
        qualification: "",
        steps: "",
        introduction: "",
        url: ""
      },
      virtual_form: {
        name: "",
        date: "",
        qualification: "",
        steps: "",
        gender: "",
        coverurl: "",
        level: "",
        levelsURLS: [],
        // 每关步数
        level_steps: "",
        introduction: "",
        virtual_introduction: ""
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        date: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        qualification: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        cover: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        steps: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        introduction: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        gender: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        level: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        level_steps: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        level_photo: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        level_number: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        level_setnumber: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        virtue_introduction: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 上传普通活动
    async normal_activity_add() {
      let date = `${this.normal_form.date}`;
      let start = date.split(",")[0];
      let end = date.split(",")[1];
      const { data: res } = await this.$http.post(
        `/activity/public/`,
        {
          activityDO: {
            activityBeginTime: start,
            activityEndTime: end,
            activityIntroduction: this.normal_form.introduction,
            activityName: this.normal_form.name,
            activityPhotoUrl: this.normal_form.url,
            createDate: null,
            getMedalsTargetStepNum: this.normal_form.steps,
            id: null,
            modifiedDate: null,
            publishPerson: "admin",
            routeStatus: 0,
            staffStatus: this.normal_form.qualification
          }
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      if (res.code === 200) {
        this.$message.success("添加成功");
        this.$router.replace("/a_list");
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("添加失败");
      }
    },
    // 普通活动封面
    uploadSuccess(response) {
      // 上传成功则绑定封面地址
      this.normal_form.url = response.entity.imgURL;
    },
    // 虚拟活动封面
    cover_uploadSuccess(response) {
      this.virtual_form.coverurl = response.entity.imgURL;
    },
    uploadSuccess_level(response,file,fileList) {
      // this.virtual_form.
      // console.log(fileList);
      let i = 0;
      fileList.forEach(item => {
        this.virtual_form.levelsURLS[i] = item.response.entity.imgURL;
        i++;
      })
    },
    // 上传虚拟活动路线
    async virtual_activity_add() {
      let date = `${this.virtual_form.date}`;
      let start = date.split(",")[0];
      let end = date.split(",")[1];
      let array = this.virtual_form.level_steps.split(",");
      const { data: res } = await this.$http.post(
        `/activity/public/`,
        {
          activityDO: {
            activityBeginTime: start,
            activityEndTime: end,
            activityIntroduction: this.virtual_form.introduction,
            activityName: this.virtual_form.name,
            activityPhotoUrl: this.virtual_form.coverurl,
            createDate: null,
            getMedalsTargetStepNum: this.virtual_form.steps,
            id: null,
            modifiedDate: null,
            publishPerson: "admin",
            routeStatus: 1,
            staffStatus: this.virtual_form.qualification
          },
          routeDO: {
            checkpointNum: this.virtual_form.level,
            createDate: null,
            id: null,
            modifiedDate: null,
            photoURLs: this.virtual_form.levelsURLS,
            pointTargetSteps: array,
            rtf: this.virtual_form.virtual_introduction,
            sexType: this.virtual_form.gender
          }
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      console.log(res);
      if (res.code === 200) {
        this.$message.success("添加成功");
        this.$router.replace("/a_list");
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("添加失败");
      }
      console.log(res);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    }
  }
};
</script>

<style lang="less" scoped>
.el-tab-pane {
  padding-top: 30px;
}
.activity_input {
  width: 450px;
}
.date_time_input {
  width: 150px;
  display: inline-block;
}
</style>
