<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/k_list' }">知识列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加知识</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <div class="title">添加知识</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="题目"
          prop="question"
        >
          <el-input
            v-model="ruleForm.question"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="A选项"
          prop="sectionA"
        >
          <el-input
            v-model="ruleForm.sectionA"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="B选项"
          prop="sectionB"
        >
          <el-input
            v-model="ruleForm.sectionB"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="C选项"
          prop="sectionC"
        >
          <el-input
            v-model="ruleForm.sectionC"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="D选项"
          prop="sectionD"
        >
          <el-input
            v-model="ruleForm.sectionD"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="答案"
          prop="answer"
        >
          <el-input
            v-model="ruleForm.answer"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item label="解析">
          <el-input
            v-model="ruleForm.analysis"
            class="k_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="活动"
          prop="activity"
        >
          <el-select
            v-model="ruleForm.activity"
            placeholder="请选择"
            class="select"
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        round
        class="btn"
        @click="post_knowledge"
      >确认</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "K_add",
  data() {
    return {
      ruleForm: {
        question: "",
        sectionA: "",
        sectionB: "",
        sectionC: "",
        sectionD: "",
        answer: "",
        activity: "",
        analysis: ""
      },
      rules: {
        question: [{ required: true, message: "请输入题目", trigger: "blur" }],
        sectionA: [{ required: true, message: "请输入选项A", trigger: "blur" }],
        sectionB: [{ required: true, message: "请输入选项B", trigger: "blur" }],
        sectionC: [{ required: true, message: "请输入选项C", trigger: "blur" }],
        sectionD: [{ required: true, message: "请输入选项D", trigger: "blur" }],
        answer: [{ required: true, message: "请输入答案", trigger: "blur" }],
        activity: [{ required: true, message: "请选择活动", trigger: "blur" }]
      },
      activity: "",
      activitylist: []
    };
  },
  methods: {
    // 获取活动列表
    async getActivitylist() {
      const { data: res } = await this.$http.get(`/activity/allList`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 0,
          pageSize: 100,
          sortProperty: "id"
        }
      });
      console.log(res);
      this.activitylist = res.entity.list;
    },

    // 上传知识问答
    async post_knowledge() {
      const { data: res } = await this.$http.post(`/knowledge`,
      {
        activityId: this.ruleForm.activity,
        activityTitle: "",
        analysis: this.ruleForm.analysis,
        answer: this.ruleForm.answer,
        contentA: this.ruleForm.sectionA,
        contentB: this.ruleForm.sectionB,
        contentC: this.ruleForm.sectionC,
        contentD: this.ruleForm.sectionD,
        createDate: null,
        id: null,
        modifiedDate: null,
        title: this.ruleForm.question
      } 
      ,{
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        }
      });
      // console.log(res);
      if(res.code === 200) {
        this.$message.success('添加成功')
      } else if(res.code === 500) {
        this.$message.error(this.res.message);
      } else {
        this.$message.error('添加失败');
      }
    }
  },

  created() {
    this.getActivitylist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.k_input {
  width: 450px;
}
.btn {
  margin-left: 100px;
}
.select {
  width: 450px;
}
</style>
