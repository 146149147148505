<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>异常监控</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 查询区域 -->
    <el-card>
      <div class="title">查询</div>
      <el-form ref="form" :model="form" label-width="80px" class="form">
        <!-- 时间选择 -->
        <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" class="time"> </el-date-picker>
        <!-- 步数阈值 -->
        <el-form-item label="步数阈值">
          <el-input v-model="form.steps" class="input"></el-input>
        </el-form-item>

        <!-- 搜索 -->
        <el-button type="primary" round class="input_button" @click="search">搜索</el-button>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-button round type="warning" @click="download" style="float:right">导出报表</el-button>
      <el-table :data="monitor_list" stripe style="width: 100%">
        <el-table-column prop="monitoruser.jobnumber" label="账号"> </el-table-column>
        <el-table-column prop="monitoruser.username" label="姓名"> </el-table-column>
        <el-table-column prop="monitoruser.departname" label="部门"> </el-table-column>
        <el-table-column prop="monitoruser.phone" label="手机号码"> </el-table-column>
        <el-table-column prop="times" label="超出阈值次数"> </el-table-column>
        <el-table-column label="详细">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-document" circle @click="showlist(scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 详细对话框 -->
    <el-dialog title="详细" :visible.sync="dialogVisible" width="30%">
      <el-table :data="stepList" stripe style="width: 100%">
        <el-table-column prop="date" label="日期"> </el-table-column>
        <el-table-column prop="step" label="步数"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "M_list",
  data() {
    return {
      form: {
        date: "",
        steps: "",
      },
      monitor_list: [],
      stepList: [],
      dialogVisible: false,
      start: "",
      end: "",
    };
  },
  methods: {
    async search() {
      let date = `${this.form.date}`;
      if (date === "") {
        this.start = null;
        this.end = null;
      } else {
        this.start = date.split(",")[0].substring(0, 10);
        this.end = date.split(",")[1].substring(0, 10);
      }
      if (this.form.steps === "") {
        this.$message.error("请输入步数阈值");
      }
      console.log(this.start);
      console.log(this.end);
      try {
        showLoading();
        const { data: res } = await this.$http.get(`/monitor/getStepsOverThreshold`, {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token"),
          },
          params: {
            threshold: this.form.steps,
            firstStamp: this.start,
            lastStamp: this.end,
          },
        });
        if (res) {
          hideLoading();
        }
        console.log(res);
        this.monitor_list = res.entity;
        console.log(this.monitor_list);
      } catch (error) {
        hideLoading();
        this.$message.error("请求列表失败");
      }
    },

    // 详情
    showlist(index) {
      this.stepList = this.monitor_list[index].stepList;
      console.log(this.stepList);
      this.dialogVisible = true;
    },
    async download() {
      if (this.form.steps == "") {
        this.$message.warning("请输入步数阈值");
      } else {
        await this.$http({
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
          method: "get",
          url: `/excel/getStepsOverThresholdExcel`,
          responseType: "blob",
          params: {
            threshold: this.form.steps,
            firstStamp: this.start,
            lastStamp: this.end,
          },
        }).then((res) => {
          let blob = new Blob([res.data], { type: `application/vnd.ms-excel;charset=utf-8` });
          let url = window.URL.createObjectURL(blob);
          let aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = url;
          // 定义文件名
          aLink.setAttribute("download", `异常信息报表.xls`);
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
          window.URL.revokeObjectURL(url);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  width: 60%;
  margin-top: 15px;
  justify-content: space-between;
}
.input_button {
  height: 50%;
  margin-left: 10%;
}
</style>
