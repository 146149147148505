import Vue from 'vue'
import { Aside, Breadcrumb, BreadcrumbItem, Button, Card, Container, DatePicker, Dialog, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Header, Input, Main, Menu, MenuItem, MenuItemGroup, Message, Option, Radio, RadioButton, RadioGroup, Select, Step, Steps, Submenu, Table, TableColumn, TabPane, Tabs, TimePicker, Upload, MessageBox, Loading, Pagination, Tag } from 'element-ui'

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

// 使用loading
let loadingCount = 0;
let loading;

// 等待遮罩层 - loading
const startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...',//可以自定义文字
    spinner:'el-icon-loading',//自定义加载图标类名
    background: 'rgba(0, 0, 0, 0.7)'//遮罩层背景色
  });
};
 
const endLoading = () => {
  loading.close();
};
 
export const showLoading = () => {
  if (loadingCount === 0) {
    startLoading();
  }
  loadingCount += 1;
};
 
export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(RadioButton)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(MenuItemGroup)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Tag)