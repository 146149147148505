<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/k_list' }">知识列表</el-breadcrumb-item>
      <el-breadcrumb-item>虚拟步数获取记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 导出excel -->
    <el-card>
      <div class="title">导出excel</div>
      <el-form
        ref="form"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item
          label="活动"
          prop="activity"
        >
          <el-select
            v-model="ruleForm.activity"
            placeholder="请选择"
            class="select"
            clearable
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="warning"
            round
            class="btn"
            @click="getvirtualexcel"
          >导出记录</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 表格部分 -->
    <el-card>
      <el-table
        :data="virtualsteps_activitylist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="activityTitle"
          label="活动"
        >
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户"
        >
        </el-table-column>
        <el-table-column
          prop="step"
          label="获取的虚拟步数"
        >
        </el-table-column>
        </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "K_virtualsteps_record",
  data() {
    return {
      activitylist: [],
      ruleForm: {
        activity: ""
      },
      rules: {
        activity: [
          { required: true, message: "请输入活动名称", trigger: "blur" }
        ]
      },
      virtualsteps_activitylist: [],
      activity: "",
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 获取活动列表
    async get_activitylist() {
      const { data: res } = await this.$http.get(`/activity/allList`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 0,
          pageSize: 1000,
          sortProperty: "id"
        }
      });
      // console.log(res);
      this.activitylist = res.entity.list;
    },

    // 虚拟步数获取记录
    async get_virtualsteps() {
      try {
        showLoading();
        const { data: res } = await this.$http.get(
          `/knowledge/knowledgeVirtualStep/list`,
          {
            headers: {
              Accept: "application/json;charset=UTF-8",
              Token: window.sessionStorage.getItem("token")
            },
            params: {
              pageNum: this.currentpage,
              pageSize: 10,
              sortProperty: "id"
            }
          }
        );
        if (res) {
          hideLoading();
        }
        this.virtualsteps_activitylist = res.entity.list;
        this.total = res.entity.total;
        this.pagecount = res.entity.pages;
        // console.log(res);
      } catch (error) {
        hideLoading();
        this.$message.error("请求列表失败");
      }
    },

    // 导出虚拟步数获取记录报表
    async getvirtualexcel() {
      if (this.ruleForm.activity === "") {
        this.$message.error("请选择活动");
      } else {
        const obj = `https://api.bnuztuhms.cn/knowledge/getVirtualStepExcel?activityId=${
          this.ruleForm.activity
        }&Token=${window.sessionStorage.getItem("token")}`;
        window.location.href = obj;
      }
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.get_virtualsteps();
    }
  },
  created() {
    this.get_activitylist();
    this.get_virtualsteps();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.btn {
  margin-left: 100px;
}
</style>
