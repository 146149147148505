<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>部门列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <!-- 导出记录 -->
      <el-button
        type="warning"
        round
        @click="download"
      >导出记录</el-button>
      <!-- 表格 -->
      <el-table
        :data="depart_list"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="departName"
          label="部门名"
        >
        </el-table-column>
        <el-table-column
          prop="departPhone"
          label="部门联系电话"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改部门信息弹框 -->
    <el-dialog
      title="部门信息修改"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="modify_info">
        <el-form-item
          label="部门名："
          label-width="120px"
        >
          <el-input
            v-model="modify_info.depart_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="部门联系电话："
          label-width="120px"
        >
          <el-input
            v-model="modify_info.depart_phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {showLoading, hideLoading} from '@/plugins/element.js'
export default {
  name: "D_list",
  data() {
    return {
      depart_list: [],
      dialogFormVisible: false,
      modify_info: {
        depart_name: "",
        depart_phone: ""
      },
      id: Number
    };
  },
  created() {
    this.getdepartmentlist();
  },
  methods: {
    // 获取部门数据
    async getdepartmentlist() {
      showLoading()
      const { data: res } = await this.$http.get(`/departs/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 1,
          pageSize: 100
        }
      });
      this.depart_list = res.entity.list;
      if(res) {
        hideLoading();
      }
    },

    // 修改部门信息
    edit(index) {
      this.dialogFormVisible = true;
      this.modify_info.depart_name = this.depart_list[index].departName;
      this.modify_info.depart_phone = this.depart_list[index].departPhone;
      this.id = this.depart_list[index].id;
      console.log(this.id);
    },

    // 删除部门
    async del(index) {
      const id = this.depart_list[index].id;
      const { data: res } = await this.$http.delete(`/departs/${id}`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        }
      });
      if (res.code === 200) {
        this.$message.success("删除成功");
        this.getdepartmentlist();
      } else {
        this.$message.error("删除失败");
      }
    },

    // 编辑部门信息
    async submit() {
      // 上传修改信息
      const { data: res } = await this.$http.put(
        `/departs/${this.id}`,
        {
          departName: this.modify_info.depart_name,
          departPhone: this.modify_info.depart_phone
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      console.log(res);
      if(res.code === 200) {
        this.$message.success('修改成功')
        this.dialogFormVisible = false;
        this.getdepartmentlist();
      } else {
        this.$$message.error('修改失败')
      }
    },
    // 导出记录
    download() {
      const obj = `https://api.bnuztuhms.cn/departs/getDepartExcel?Token=${window.sessionStorage.getItem("token")}`;
      window.location.href = obj;
    }
  }
};
</script>

<style lang="less" scoped>
.el-button--warning {
  float: right;
  display: block;
}
</style>
