<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/d_list' }">部门列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加部门</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="部门名称"
          prop="depart_name"
        >
          <el-input
            v-model="ruleForm.depart_name"
            class="depart_input"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门联系电话">
          <el-input
            v-model="ruleForm.depart_phone"
            class="depart_input"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        round
        @click="add_department"
      >确认</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "D_add",
  data() {
    return {
      ruleForm: {
        depart_name: "",
        depart_phone: ""
      },
      rules: {
        depart_name: [
          { required: true, message: "请输入部门名称", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    async add_department() {
      // 判断是否已输入部门名称，若无则提示并返回
      if (this.ruleForm.depart_name === "") {
        this.$message.error("请输入部门名称");
        return;
      }

      // 若有则上传信息
      const {data:res} = await this.$http.post(`/departs/public/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        departName: this.ruleForm.depart_name,
        departPhone: this.ruleForm.depart_phone
      });

      // 根据返回码提示信息
      if(res.code === 200) {
        this.$message.success("添加成功");
        this.$router.replace("/d_list")
      } else {
        this.$message.error(res.message);
        console.log(res);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.depart_input {
  width: 450px;
}
.el-button--primary {
  margin-left: 100px;
}
</style>
