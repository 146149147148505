<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <!-- 查询 -->
    <el-card>
      <div class="title">查询</div>
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
      >
        <!-- 用户类型 -->
        <el-form-item label="类型">
          <el-select
            v-model="form.type"
            placeholder="请选择用户类型"
          >
            <el-option
              label="全部用户"
              value="0"
            ></el-option>
            <el-option
              label="仅工会员工"
              value="1"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 部门 -->
        <el-form-item label="部门">
          <el-select
            v-model="form.depart"
            placeholder="请选择部门"
            clearable
          >
            <el-option
              v-for="item in depart_list"
              :key="item.index"
              :label="item.departName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 姓名 -->
        <el-form-item label="姓名">
          <el-input v-model="form.username"></el-input>
        </el-form-item>

        <!-- 搜索 -->
        <el-button
          type="primary"
          round
          class="input_button"
          @click="serachuser"
        >搜索</el-button>
      </el-form>
    </el-card>

    <!-- 表格 -->
    <el-card>
      <el-button
        round
        type="warning"
        @click="download"
        style="float:right"
      >导出报表</el-button>
      <el-table
        :data="userlist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="jobNumber"
          label="账号"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="departName"
          label="部门"
        >
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="微信昵称"
        >
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
        >
        </el-table-column>
        <el-table-column
          prop="phoneNumber"
          label="联系电话"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
        >
        </el-table-column>
        <el-table-column
          prop="idNumber"
          label="身份证号"
        >
        </el-table-column>
        <el-table-column
          prop="birthDate"
          label="生日日期"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <!-- 修改用户信息弹框 -->
    <el-dialog
      title="用户信息修改"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="modify_info">
        <el-form-item
          label="账号"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.jobNumber"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_name"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_gender"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="部门"
          label-width="120px"
        >
          <el-select
            v-model="modify_info.user_depart"
            placeholder="请选择"
          >
            <el-option
              v-for="item in depart_list"
              :key="item.index"
              :label="item.departName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="联系方式"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_phone"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="身份证号"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_idcardnum"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="邮箱"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_email"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="出生日期"
          label-width="120px"
        >
          <el-input
            v-model="modify_info.user_birth"
            autocomplete="off"
            class="dialog_input"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "U_list",
  data() {
    return {
      form: {
        type: "0",
        depart: "",
        username: ""
      },
      modify_info: {
        user_id: "",
        user_name: "",
        user_depart: "",
        user_wechat: "",
        user_gender: "",
        user_phone: "",
        user_email: "",
        user_idcardnum: "",
        user_birth: "",
        user_isJobMember: "",
        user_nickName: "",
        user_passWord: "",
        user_isFirstBuding: "",
        user_jobNumber: ""
      },
      depart_list: [],
      depart_name_list: [],
      userlist: [],
      user_id: "",
      user_name: "",
      user_depart: "",
      user_wechat: "",
      user_gender: "",
      user_phone: "",
      user_email: "",
      user_idcardnum: "",
      user_birth: "",
      dialogFormVisible: false,
      currentpage: 1,
      total: 0,
      pagecount: 0,
      search: 0
    };
  },
  created() {
    this.getuserlist();
    this.getdepartlist();
  },
  methods: {
    // 获取用户列表
    async getuserlist() {
      showLoading();
      const { data: res } = await this.$http.get(`/userinfo/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: this.currentpage,
          pageSize: 10,
          sortProperty: "id",
          sortDirection: "ASC",
          id: 0,
          departid: null,
          name: null
        }
      });
      console.log(res);
      if (res) {
        hideLoading();
      }
      this.userlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      if (this.form.depart === "" && this.form.username === "") {
        this.currentpage = currentpage;
        this.getuserlist();
      } else {
        this.currentpage = 1;
        this.serachuser();
      }
    },

    // 获取部门列表
    async getdepartlist() {
      const { data: res } = await this.$http.get(`/departs/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 1,
          pageSize: 100
        }
      });
      this.depart_list = res.entity.list;
    },

    // 查询用户
    async serachuser() {
      if (this.form.depart === "" && this.form.username === "") {
        this.currentpage = 1;
      }
      showLoading();
      const { data: res } = await this.$http.get(`/userinfo/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: this.currentpage,
          pageSize: 200,
          sortProperty: "id",
          sortDirection: "ASC",
          id: this.form.type,
          departid: this.form.depart,
          name: this.form.username
        }
      });
      if (res) {
        hideLoading();
      }
      this.userlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
    },

    // 删除用户
    async del(index) {
      let id = this.userlist[index].id;
      const { data: res } = await this.$http.delete(`/userinfo/${id}`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        }
      });
      console.log(res);
      if (res.code === 200) {
        this.$message.success("成功删除");
        this.serachuser();
      } else {
        this.$message.error("删除失败");
      }
    },

    // 修改用户信息
    async edit(index) {
      this.dialogFormVisible = true;
      this.id = this.userlist[index].id;
      this.modify_info.jobNumber = this.userlist[index].jobNumber;
      this.modify_info.user_name = this.userlist[index].name;
      this.modify_info.user_depart = this.userlist[index].depart_id;
      this.modify_info.user_gender = this.userlist[index].sex;
      this.modify_info.user_phone = this.userlist[index].phoneNumber;
      this.modify_info.user_email = this.userlist[index].email;
      this.modify_info.user_idcardnum = this.userlist[index].idNumber;
      this.modify_info.user_birth = this.userlist[index].birthDate;
      this.modify_info.isJobMember = this.userlist[index].isJobMember;
      this.modify_info.nickName = this.userlist[index].nickName;
      this.modify_info.user_id = this.userlist[index].user_id;
      this.modify_info.user_passWord = this.userlist[index].passWord;
      this.modify_info.isFirstBuding = this.userlist[index].isFirstBuding;
      this.modify_info.jobNumber = this.userlist[index].jobNumber;
      // console.log(this.id);
      // console.log(this.userlist);
    },

    // 提交修改用户信息
    async submit() {
      const { data: res } = await this.$http.put(
        `/userinfo/${this.id}`,
        {
          birthDate: this.modify_info.user_birth,
          createDate: null,
          depart_id: this.modify_info.user_depart,
          email: this.modify_info.user_email,
          headUrl: null,
          id: this.id,
          idNumber: this.modify_info.user_idcardnum,
          isFirstBuding: this.modify_info.isFirstBuding,
          isJobMember: this.modify_info.user_isJobMember,
          jobNumber: this.modify_info.jobNumber,
          modifiedDate: null,
          name: this.modify_info.user_name,
          nickName: this.modify_info.user_nickName,
          passWord: null,
          phoneNumber: this.modify_info.user_phone,
          sex: this.modify_info.user_gender,
          user_id: this.modify_info.user_id
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      console.log(res);
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.dialogFormVisible = false;
        this.getuserlist();
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("修改失败");
      }
      // console.log(this.modify_info.user_depart);
    },

    // 导出表格
    download() {
      const obj = `https://api.bnuztuhms.cn/userinfo/getUserInfoAddUp?Token=${window.sessionStorage.getItem(
        "token"
      )}`;
      window.location.href = obj;
      // console.log(obj);
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.el-form-item {
  display: inline-block;
  padding-right: 100px;
}

.el-form-item__content {
  width: 77%;
}
</style>
