<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/n_list' }">新闻列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加新闻</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 添加新闻 -->
    <el-card>
      <div class="title">添加新闻</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <!-- 上传图片 -->
        <el-form-item
          label="封面图片"
          prop="dialogImageUrl"
        >
          <!-- 记得填action接口 -->
          <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img
              width="100%"
              :src="dialogImageUrl"
              alt=""
            >
          </el-dialog>
        </el-form-item>

        <!-- 发布时间 -->
        <el-form-item label="新闻发布时间" prop="date">
          <div class="block">
            <el-date-picker
              v-model="date"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <!-- 新闻类别 -->
        <el-form-item label="新闻类别" prop="type">
         <el-radio-group v-model="type">
            <el-radio :label="1">活动</el-radio>
            <el-radio :label="2">通知</el-radio>
            <el-radio :label="3">知识</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 新闻标题 -->
        <el-form-item label="新闻标题" prop="newstitle">
          <el-input v-model="newstitle" placeholder="请输入新闻标题" class="news_input"></el-input>
        </el-form-item>

        <!-- 新闻作者 -->
        <el-form-item label="新闻作者" prop="newstitle">
          <el-input v-model="newstitle" placeholder="请输入新闻作者" class="news_input"></el-input>
        </el-form-item>

        <!-- 新闻内容 -->
        <el-form-item label="新闻内容" prop="newscontent">
          <quill-editor
              ref="myTextEditor"
              v-model="newscontent"
              :options="editorOption"
              style="height:600px;"
            ></quill-editor>
        </el-form-item>

        <!-- 确认按钮 -->
        <el-button type="primary" round class="btn">添加新闻</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "N_add",
  data() {
    return {
      ruleForm: {
        dialogImageUrl: "",
        date: "",
        type: "",
        newstitle: "",
        newscontent: ""
      },
      rules: {
        dialogImageUrl: { required: true },
        date: { required: true, message: "请选择发布日期", trigger: "blur" },
        type: { required: true, message: "请选择发布日期", trigger: "blur" },
        newstitle: { required: true, message: "请输入新闻标题", trigger: "blur" },
        newscontent: { required: true, message: "请输入新闻内容", trigger: "blur" }
      },
      file: [],
      dialogImageUrl: "",
      dialogVisible: false,
      date: "",
      type: "",
      newstitle: "",
      newscontent: "",
      // 查一下editorOption的格式
      editorOption: {}
    };
  },
  methods: {
    handleRemove() {},
    handlePictureCardPreview() {}
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.news_input {
  width: 450px;
}
.btn {
  margin-left: 120px;
}
</style>
