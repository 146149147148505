<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/u_list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加用户</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="title">添加用户</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="账号"
          prop="jobNumber"
        >
          <el-input
            v-model="ruleForm.jobNumber"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="username"
        >
          <el-input
            v-model="ruleForm.username"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          prop="gender"
        >
          <el-select v-model="ruleForm.gender">
            <el-option
              label="男"
              value="男"
            ></el-option>
            <el-option
              label="女"
              value="女"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="phone"
        >
          <el-input
            v-model="ruleForm.phone"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="email"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="部门"
          prop="department"
        >
          <el-select
            v-model="ruleForm.department"
            placeholder="请选择部门"
            clearable
          >
            <el-option
              v-for="item in departmentlist"
              :key="item.index"
              :label="item.departName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号码">
          <el-input
            v-model="idnumber"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item label="出生日期">
          <el-date-picker
            v-model="birthdate"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="初始密码"
          prop="psw"
        >
          <el-input
            v-model="ruleForm.psw"
            class="useradd_input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="checkPass"
        >
          <el-input
            v-model="ruleForm.checkPass"
            class="useradd_input"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        round
        @click="add_user"
      >提交</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "U_add",
  data() {
    return {
      departmentlist: [],
      ruleForm: {
        jobNumber: "",
        username: "",
        gender: "",
        phone: "",
        psw: "",
        checkPass: ""
      },
      rules: {
        jobNumber: [{ required: true, message: "请输入账号", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入电话号码或手机号码",
            trigger: "blur"
          }
        ],
        psw: [{ required: true, message: "请输入初始密码", trigger: "blur" }],
        checkPass: [
          { required: true, message: "请再输入一遍密码", trigger: "blur" }
        ],
        department: [{ required: true, message: "请选择部门", trigger: "blur" }]
      },
      email: "",
      idnumber: null,
      birthdate: ""
    };
  },
  methods: {
    async add_user() {
      if (this.ruleForm.psw !== this.ruleForm.checkPass) {
        this.$message.error("两次密码不一致");
      } else {
        // 新增用户
        const { data: res } = await this.$http.post(`/userinfo/public`, {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          // 账号
          jobNumber: this.ruleForm.jobNumber,
          // 姓名
          name: this.ruleForm.username,
          // 性别
          sex: this.ruleForm.gender,
          // 部门
          depart_id: this.ruleForm.department,
          // 密码
          passWord: this.ruleForm.psw,
          // 联系方式
          phoneNumber: this.ruleForm.phone,
          // 邮箱
          email: this.email,
          // 生日
          birthDate: this.brithday,
          // 身份证号码
          idNumber: this.idnumber
        });
        console.log(res);
        if(res.code === 200) {
          this.$message.success('添加成功')
          // this.$router.go(0)
        } else if(res.code === 500) {
          this.$message.error(res.message)
        } else {
          this.$message.error('添加失败')
        }
      }
    },

    // 获取部门列表
    async getdepartlist() {
      const { data: res } = await this.$http.get(`/departs/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 1,
          pageSize: 100
        }
      });
      console.log(res);
      this.departmentlist = res.entity.list;
    }
  },
  created() {
    this.getdepartlist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.useradd_input {
  width: 450px;
}
.el-button--primary {
  margin-left: 100px;
}
</style>
