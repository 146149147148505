<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="全部"
          name=0
        ></el-tab-pane>
        <el-tab-pane
          label="进行中"
          name=1
        ></el-tab-pane>
        <el-tab-pane
          label="未开始"
          name=2
        ></el-tab-pane>
        <el-tab-pane
          label="已结束"
          name=3
        ></el-tab-pane>
      </el-tabs>
      <el-table
        :data="ActivityList"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="activityName"
          label="活动名"
          width="500"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.type"
              disable-transitions
            >{{scope.row.status}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="signupUserNum"
          label="报名人数"
        >
        </el-table-column>
        <el-table-column
          prop="activityBeginTime"
          label="开始时间"
        >
        </el-table-column>
        <el-table-column
          prop="activityEndTime"
          label="结束时间"
        >
        </el-table-column>
        <el-table-column
          prop="publishPerson"
          label="发布者"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="500"
        >
          <template slot-scope="scope">
            <el-button
              round
              @click="enroll(scope.$index)"
            >
              查看报名情况
            </el-button>
            <el-button
              type="warning"
              round
              @click="rank(scope.$index)"
            >
              查看步数排行榜
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "A_list",
  data() {
    return {
      activeName: 0,
      ActivityList: [],
      status: "",
      type: 0,
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  created() {
    // 请求列表数据
    this.getdata();
  },
  methods: {
    // 选项卡切换
    handleClick() {
      this.type = this.activeName;
      this.getdata();
      // console.log(this.ActivityList);
    },

    // 请求列表数据
    async getdata() {
      showLoading();
      const { data: res } = await this.$http.get(
        `/activity/activityDTOList/${this.type}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          params: {
            pageNum: this.currentpage,
            pageSize: 8
          }
        }
      );
      this.ActivityList = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
      if (res) {
        hideLoading();
      }
      this.ActivityList.forEach(item => {
        if (item.isFinished === true) {
          item.status = "已结束";
          item.type = "danger";
        } else if (item.isStarted === false) {
          item.status = "未开始";
          item.type = "primary";
        } else if (item.isStarted === true && item.isFinished === false) {
          item.status = "进行中";
          item.type = "success";
        }
      });
      console.log(this.ActivityList);
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.getdata();
    },

    // 编辑活动
    edit(index) {
      this.$router.push({
        path: "/a_modify",
        query: {
          activity_id: this.ActivityList[index].id,
          activity_name: this.ActivityList[index].activityName
        }
      });
    },

    // 删除活动
    del(index) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data: res } = await this.$http.delete(
            `/activity/${this.ActivityList[index].id}`,
            {
              headers: {
                Accept: "application/json;charset=UTF-8",
                Token: window.sessionStorage.getItem("token")
              }
              // params: {
              //   id: this.ActivityList[index].id
              // }
            }
          );
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getdata();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      console.log(index);
    },

    // 查看报名情况
    enroll(index) {
      // 跳转并将活动id和活动名传过去
      this.$router.push({
        path: "/a_enroll",
        query: {
          activity_id: this.ActivityList[index].id,
          activity_name: this.ActivityList[index].activityName
        }
      });
    },

    // 查看步数排行榜
    rank(index) {
      // 跳转并将活动id和活动名传过去
      this.$router.push({
        path: "/a_rank",
        query: {
          activity_id: this.ActivityList[index].id,
          activity_name: this.ActivityList[index].activityName
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
</style>
