<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/k_list' }">知识列表</el-breadcrumb-item>
      <el-breadcrumb-item>虚拟步数设置</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 添加 -->
    <el-card>
      <div class="title">添加</div>
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 活动名 -->
        <el-form-item
          label="活动"
          prop="activity"
        >
          <el-select
            v-model="form.activity"
            placeholder="请选择"
            class="select"
            clearable
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 类型 -->
        <el-form-item
          label="类型"
          prop="activity"
        >
          <el-select
            v-model="form.type"
            placeholder="请选择"
            class="select"
            clearable
          >
            <el-option
              label="固定"
              value="0"
            ></el-option>
            <el-option
              label="百分比"
              value="1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="虚拟步数值">
          <el-input
            v-model="form.stepvalue"
            class="stepvalue"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          round
          @click="add"
        >添加</el-button>
      </el-form>
    </el-card>

    <!-- 表格 -->
    <el-card>
      <el-table
        :data="virtual_steps_list"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="activityName"
          label="活动"
          width="550"
        >
        </el-table-column>
        <el-table-column
          prop="typeName"
          label="类型"
        >
        </el-table-column>
        <el-table-column
          prop="value"
          label="虚拟步数值"
        >
        </el-table-column>
        <el-table-column
          prop="per"
          label="每题步数"
        >
        </el-table-column>
        <el-table-column
          prop="totalsetp"
          label="活动步数要求"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <!-- 修改虚拟步数设置信息 -->
    <el-dialog
      title="虚拟步数设置修改"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 活动名 -->
        <el-form-item
          label="活动"
          prop="activity"
        >
          <el-select
            v-model="modifyform.activity"
            placeholder="请选择"
            class="select"
            clearable
          >
            <el-option
              v-for="item in activitylist"
              :key="item.id"
              :label="item.activityName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 类型 -->
        <el-form-item
          label="类型"
          prop="activity"
        >
          <el-select
            v-model="modifyform.type"
            placeholder="请选择"
            class="select"
            clearable
          >
            <el-option
              label="固定"
              value="0"
            ></el-option>
            <el-option
              label="百分比"
              value="1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="虚拟步数值">
          <el-input
            v-model="modifyform.stepvalue"
            class="stepvalue"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "K_virtualstep_set",
  data() {
    return {
      dialogFormVisible: false,
      id: "",
      form: {
        activity: "",
        date: "",
        type: "",
        stepvalue: 0
      },
      modifyform: {
        activity: "",
        date: "",
        type: "",
        stepvalue: ""
      },
      activitylist: [],
      virtual_steps_list: [],
      activityid: "",
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 获取活动列表
    async getActivitylist() {
      const { data: res } = await this.$http.get(`/activity/allList`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 0,
          pageSize: 100,
          sortProperty: "id"
        }
      });
      console.log(res);
      this.activitylist = res.entity.list;
    },

    // 获取虚拟步数数据
    async getvirtualstepslist() {
      showLoading();
      try {
        const { data: res } = await this.$http.get(
          `/knowledge/knowledgeValueSum/list`,
          {
            headers: {
              Accept: "application/json;charset=UTF-8",
              Token: window.sessionStorage.getItem("token")
            },
            params: {
              pageNum: this.currentpage,
              pageSize: 5
            }
          }
        );
        if (res) {
          hideLoading();
        }
        // console.log(res);
        this.virtual_steps_list = res.entity.list;
        this.virtual_steps_list.forEach(async item => {
          const { data: result } = await this.$http.get(
            `/knowledge/${item.activityId}`,
            {
              headers: {
                Accept: "application/json;charset=UTF-8",
                Token: window.sessionStorage.getItem("token")
              },
              params: {
                pageNum: 1,
                pageSize: 1000
              }
            }
          );
          item.per = item.value / result.entity.length;
          // console.log(item.per);
        })
      } catch (error) {
        hideLoading();
        this.$message.error("获取虚拟步数列表失败");
      }
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.getvirtualstepslist();
    },

    // 添加
    async add() {
      const { data: res } = await this.$http.post(
        `/knowledge/knowledgeValueSum`,
        {
          activityId: this.form.activity,
          createDate: null,
          id: null,
          knowledgeBeginTime: null,
          knowledgeEndTime: null,
          modifiedDate: null,
          type: this.form.type,
          value: this.form.stepvalue
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      if (res.code === 200) {
        this.$message.success("添加成功");
        this.getvirtualstepslist();
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("添加失败");
      }
    },

    // 删除
    async del(index) {
      let id = this.virtual_steps_list[index].id;
      console.log(this.virtual_steps_list[index].id);
      const { data: res } = await this.$http.delete(
        `/knowledge/knowledgeValueSum/${id}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      if (res.code === 200) {
        this.$message.success("删除成功");
        this.getvirtualstepslist();
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("删除失败");
      }
    },
    // 编辑
    async edit(index) {
      let act_id = this.virtual_steps_list[index].activityId;
      this.id = this.virtual_steps_list[index].id;
      this.dialogFormVisible = true;

      const { data: result } = await this.$http.get(
        `/knowledge/knowledgeValueSum/${act_id}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      this.modifyform.activity = result.entity.activityId;
      this.modifyform.type = result.entity.type;
      this.modifyform.stepvalue = result.entity.value;
    },

    // 提交修改信息
    async submit() {
      const { data: res } = await this.$http.put(
        `/knowledge/knowledgeValueSum/${this.id}`,
        {
          activityId: this.modifyform.activity,
          createDate: null,
          id: null,
          knowledgeBeginTime: null,
          knowledgeEndTime: null,
          modifiedDate: null,
          type: this.modifyform.type,
          value: this.modifyform.stepvalue
        },
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          }
        }
      );
      console.log(res);
      if (res.code === 200) {
        this.$message.success("修改成功");
        this.dialogFormVisible = false;
        this.getvirtualstepslist();
      } else if (res.code === 500) {
        this.$message.error(res.message);
      } else {
        this.$message.error("修改失败");
      }
    }
  },
  created() {
    this.getActivitylist();
    this.getvirtualstepslist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.stepvalue {
  width: 220px;
}
.el-button.is-round {
  margin-left: 15px;
}
</style>
