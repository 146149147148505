<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>日常步数记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 查询部分 -->
    <el-card>
      <div class="title">查询</div>
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="时间段:">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-button
            round
            type="primary"
            @click="search"
          >搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 表格部分 -->
    <el-card>
      <el-button
        round
        type="warning"
        @click="download"
      >导出表格</el-button>
      <el-table
        :data="steplist"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="jobNumber"
          label="账号"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="departName"
          label="部门"
        >
        </el-table-column>
        <el-table-column
          prop="allStep"
          label="步数"
          sortable
        >
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "R_step",
  data() {
    return {
      date: "",
      steplist: [],
      currentpage: 1,
      total: 0,
      pagecount: 0,
      Property: "id",
      Direction: "ASC"
    };
  },
  methods: {
    // 搜索获取列表数据
    async search() {
      if (this.date === "") {
        this.$message.error("请选择日期");
      } else {
        showLoading();
        var date = `${this.date}`;
        let start = date.split(",")[0].substring(0, 10);
        let end = date.split(",")[1].substring(0, 10);

        const { data: res } = await this.$http.get(`/steps/getStepsByTime`, {
          headers: {
            Accept: "application/json;charset=UTF-8",
            token: window.sessionStorage.getItem("token")
          },

          params: {
            firstTime: start,
            lastTime: end,
            pageNum: this.currentpage,
            pageSize: 10,
            sortProperty: this.Property,
            sortDirection: this.Direction
          }
        });
        if (res) {
          hideLoading();
        }
        console.log(res);
        this.steplist = res.entity.list;
        this.total = res.entity.total;
        this.pagecount = res.entity.pages;
      }
    },
    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.search();
    },
    // 导出日常步数记录
    download() {
      if (this.date === "") {
        this.$message.error("请选择日期");
      } else {
        var date = `${this.date}`;
        let start = date.split(",")[0].substring(0, 10);
        let end = date.split(",")[1].substring(0, 10);
        const obj = `https://api.bnuztuhms.cn/steps/getStepgetAddUp?firstTime=${start}&lastTime=${end}&Token=${window.sessionStorage.getItem(
          "token"
        )}  `;
        window.location.href = obj;
      }
    },

    // 排序
    sortChange(val) {
      if(val) {
        this.Property = "allStep";
        if(val.order === "ascending") {
          this.Direction = "ASC";
          this.search();
        } else if(val.order === "descending") {
          this.Direction = "DESC";
          this.search();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.el-button--primary {
  margin-left: 50px;
}
.el-button--warning {
  float: right;
}
</style>
